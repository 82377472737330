<template>
  <div>
    <div class="right">
      <div v-for="item of list" :key="item.id" class="right-item">
        <div class="right-item-img">
          <img :src="imgURL+item.image" width="100%" height="100%" alt="">
        </div>
        <div class="right-item-text">
          <div class="right-item-text-title">{{item.title}}</div>
          <div class="right-item-text-text">{{ item.details }}</div>
        </div>
        <div class="right-item-btn">
          <!-- <div class="right-item-btn-left">{{item.isprice===0?'免费':item.price}}</div> -->
          <div class="right-item-btn-right">线上预约</div>
          <div class="right-item-btn-right" v-if="tType == 'EN'">Online appointment booking</div>
        </div>
      </div>
    </div>
    <div class="paging-device">
      <el-pagination
        layout="prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current_page"
        :page-size="per_page"
        :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getticketing, imgURL} from '@/api'
export default {
  name: "about",
  data(){
    return {
      imgURL,
      tType:'CH',
      list:[],
      totalCount: 0,
      current_page: 1,
      per_page: 4,
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    // this.reactlist()
  },
  methods:{
    // 每页条数
        handleSizeChange(val) {
        this.per_page=val
        this.reactlist()
      },
      // 页数
      handleCurrentChange(val) {
        this.current_page=val
        this.reactlist()
      },
    reactlist(){
      getticketing({
        type:0
      })
      .then(res=>{
        console.log(res);
        if(res.code==200){
          this.list=res.rows
          this.totalCount=res.total
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.right{
  width: 1628px;
  height: 800px;
  display: inline-block;
  margin-right: 117px;
  //overflow: scroll;
  ////background: skyblue;
  //&::-webkit-scrollbar {
  //  width: 0px;
  //}
  .right-item{
    width: 401px;
    height: 560px;
    margin-right: 117px;
    display: inline-block;
    cursor: pointer;
    font-family: 'JDZY';
    //background: red;
    .right-item-img{
      width: 100%;
      height: 280px;
    }
    .right-item-text{
      width: 100%;
      height: 105px;
      margin-top: 24px;
      .right-item-text-title{
        font-weight: 700;
        font-size: 28px;
      }
      .right-item-text-text{
        width: 401px;
        height: 50px;
        font-size: 18px;
        color: #999999;
        margin-top: 7px;
        line-height: 26px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /*截取第三行*/
        overflow: hidden;
      }
    }
    .right-item-btn{
      margin-top: 26px;
      display: flex;
      justify-content: space-between;
      .right-item-btn-left{
        color: #37808F;
        font-size: 34px;
        width: 144px;
        height: 56px;
        line-height: 56px;
      }
      .right-item-btn-right{
        width: 144px;
        height: 56px;
        background: #337D8D;
        color: #FFFFFF;
        font-size: 20px;
        text-align: center;
        line-height: 56px;
        border-radius: 10px;
      }
    }
  }
}
/deep/.paging-device {
  margin: 70px 0 70px -50px;
  display: flex;
  justify-content: center;
}
</style>
